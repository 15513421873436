// import Swiper from 'swiper';
// import Choices from 'choices.js';
// import Forms from './forms';
// import throttle from 'lodash.throttle';
// import Velocity from 'velocity-animate';
// import ScrollOut from 'scroll-out';

console.log('loaded');

/*animação abas sobre*/

if (window.innerWidth > 1024) {
    var tabsSobre = document.querySelectorAll('.home-sobre__tab');

    for (var [i, elTab] of tabsSobre.entries()) {
        elTab.addEventListener('click', abrirSobre, false);
    }

    function abrirSobre() {
        for (var elTab of tabsSobre) {
            elTab.classList.remove('tab--open');
        }
        event.target.closest('.home-sobre__tab').classList.add('tab--open');
        // console.log(event.target);
    }
}


/*==fim animação sobre==*/

/*==compartilhar cards noticias==*/

var btsCompartilhar = document.querySelectorAll('.news-card__compartilhar');
// var cardsSocial = document.querySelectorAll('.news-card__social');

for (var elCompartilhar of btsCompartilhar) {
    elCompartilhar.addEventListener('click', abrirCompartilhar);
}

function abrirCompartilhar() {
    event.target.closest('.news-card__compartilhar').classList.toggle('active');
    // for (var elSocial of cardsSocial ) {
    //   elSocial.classList.remove('active');
    // }
    // // console.log(event.target.parentNode.parentNode.querySelectorAll('.news-card__social'));
    // var cardsCompartilhar = event.target.parentNode.parentNode.querySelectorAll('.news-card__social');
    // for (var cardCompartilhar of cardsCompartilhar ) {

    // }
    // cardCompartilhar.classList.toggle('active');
}



/*==fim compartilhar cards noticias==*/

/*==abrir busca==*/

var btBusca = document.getElementById('bt-search');
var containerBusca = document.getElementById('search-container');

btBusca.addEventListener('click', abrirBusca);

function abrirBusca() {
    containerBusca.classList.toggle('is--open');
    btBusca.classList.toggle('is--open');
    // console.log(event.target);
}

/*==fim abrir busca==*/

// ############################# HAMBURGER MENU ####################################

const hamburger = document.querySelector('.hamburger');
const navMain = document.querySelector('.nav');

hamburger.addEventListener('click', function() {
    hamburger.classList.toggle('is-active');
    navMain.classList.toggle('is-active');
});
// ##################################################################################


// ##################### REMOVE O CLICK DO MENU PRINCIPAL NO MOBILE #################

// const mainNavLink = document.querySelectorAll('.nav__link');

// if (window.innerWidth < 992) {
//     mainNavLink.forEach(function(el) {
//         el.addEventListener('click', function(e) {
//             e.preventDefault();
//         })
//     })
// }

// ##################################################################################


/*==swiper header ==*/
const HeaderCarouselContainer = document.querySelector('[data-carousel="header"]');

if (HeaderCarouselContainer) {

    const HeaderCarousel = new Swiper(HeaderCarouselContainer, {
        speed: 1000,
        autoplay: {
            delay: 5000,
        },
        // navigation: {
        //   nextEl: HomeCarouselContainer.querySelector('.swiper-button-next'),
        //   prevEl: HomeCarouselContainer.querySelector('.swiper-button-prev'),
        // },
        pagination: {
            el: HeaderCarouselContainer.querySelector('.swiper-pagination'),
            clickable: true
        },
        spaceBetween: 0,
        watchOverflow: true

    });
}

/*==swiper lar foods - receitas ==*/
const ReceitasFoodsCarouselContainer = document.querySelector('[data-carousel="receitas-foods"]');

if (ReceitasFoodsCarouselContainer) {

    const ReceitasFoodsCarousel = new Swiper(ReceitasFoodsCarouselContainer, {
        speed: 1000,
        autoplay: {
            delay: 5000,
        },
        // navigation: {
        //   nextEl: HomeCarouselContainer.querySelector('.swiper-button-next'),
        //   prevEl: HomeCarouselContainer.querySelector('.swiper-button-prev'),
        // },
        pagination: {
            el: ReceitasFoodsCarouselContainer.querySelector('.swiper-pagination'),
            clickable: true
        },
        spaceBetween: 0,
        watchOverflow: true

    });
}

/*==swiper produto ==*/
const ProdutoCarouselContainer = document.querySelector('[data-carousel="carrossel-produto"]');

if (ProdutoCarouselContainer) {

    const ProdutoCarousel = new Swiper(ProdutoCarouselContainer, {
        speed: 1000,
        effect: 'fade',
        slidesPerView: 1,
        autoplay: {
            delay: 5000,
        },
        // navigation: {
        //   nextEl: HomeCarouselContainer.querySelector('.swiper-button-next'),
        //   prevEl: HomeCarouselContainer.querySelector('.swiper-button-prev'),
        // },
        pagination: {
            el: ProdutoCarouselContainer.querySelector('.swiper-pagination'),
            clickable: true
        },
        spaceBetween: 0,
        watchOverflow: true

    });
}

/*==swiper produto ==*/
const ProdutoCarouselContainerMobile = document.querySelector('[data-carousel="carrossel-produto-mobile"]');

if (ProdutoCarouselContainerMobile) {

    const ProdutoCarouselMobile = new Swiper(ProdutoCarouselContainerMobile, {
        speed: 1000,
        effect: 'fade',
        slidesPerView: 1,
        autoplay: {
            delay: 5000,
        },
        // navigation: {
        //   nextEl: HomeCarouselContainer.querySelector('.swiper-button-next'),
        //   prevEl: HomeCarouselContainer.querySelector('.swiper-button-prev'),
        // },
        pagination: {
            el: ProdutoCarouselContainerMobile.querySelector('.swiper-pagination'),
            clickable: true
        },
        spaceBetween: 0,
        watchOverflow: true

    });
}

/*==swiper institucional - história ==*/
const HistThumbsCarouselContainer = document.querySelector('[data-carousel="historia-thumb"]');

if (HistThumbsCarouselContainer) {
    /*thumbs*/
    const HistThumbsCarousel = new Swiper(HistThumbsCarouselContainer, {
        speed: 1000,
        slidesPerView: 6,
        // autoplay: {
        //   delay: 5000,
        // },
        // navigation: {
        //   nextEl: HistThumbsCarouselContainer.querySelector('.swiper-button-next'),
        //   prevEl: HistThumbsCarouselContainer.querySelector('.swiper-button-prev'),
        // },
        // pagination: {
        //   el: HistThumbsCarouselContainer.querySelector('.swiper-pagination'),
        //   clickable: true
        // },
        spaceBetween: 15,
        watchOverflow: true

    });

    const HistoriaCarouselContainer = document.querySelector('[data-carousel="historia"]');
    /*principal*/
    const HistoriaCarousel = new Swiper(HistoriaCarouselContainer, {
        speed: 1000,
        slidesPerView: 1,
        // autoplay: {
        //   delay: 5000,
        // },
        navigation: {
            nextEl: '.swiper-button-next--historia',
            prevEl: '.swiper-button-prev--historia',
        },
        // pagination: {
        //   el: HistoriaCarouselContainer.querySelector('.swiper-pagination'),
        //   clickable: true
        // },
        spaceBetween: 0,
        watchOverflow: true,
        thumbs: {
            swiper: HistThumbsCarousel
        }

    });
}






const multipleSwiperSlides = function() {
    let sliderMain = document.querySelectorAll('[data-carousel="noticia"]')
    let sliderNav = document.querySelectorAll('[data-carousel="noticia-thumb"]')

    // Arrays to hold swiper instances
    let mainArray = [];
    let navArray = [];

    // Slider Main
    sliderMain.forEach(function(element, i) {
        // Push swiper instance to array
        mainArray.push(
            new Swiper(element, {
                loop: true,
                loopedSlides: 4
            })
        );
    });

    // Slider Nav
    sliderNav.forEach(function(element, i) {
        var self = sliderNav;
        // Push swiper instance to array
        navArray.push(
            new Swiper(element, {
                slidesPerView: 4,
                loop: true,
                loopedSlides: 4,
                slideToClickedSlide: true,
                spaceBetween: 5,
                navigation: {
                    nextEl: self[i].querySelector('.swiper-button-next'),
                    prevEl: self[i].querySelector('.swiper-button-prev')
                }
            })
        );
    });

    const checkOnPage = function() {
        if (sliderMain.length > 0 && sliderNav.length > 0) {
            let numberOfSlides = mainArray.length || navArray.length || 0;

            if (mainArray.length !== navArray.length) {
                console.warn('multipleSwiperSlides: Number of main slides and nav slides is different. Expect incorrect behaviour.');
            }

            for (let i = 0; i < numberOfSlides; i++) {
                mainArray[i].controller.control = navArray[i];
                navArray[i].controller.control = mainArray[i];
            }
        }
    }

    checkOnPage();
}

multipleSwiperSlides();



/*==swiper institucinal - noticia ==*/
// const NewsThumbsCarouselContainer = [...document.querySelectorAll('[data-carousel="noticia-thumb"]')];
// if (NewsThumbsCarouselContainer.length) {
//     /*thumbs*/
//     NewsThumbsCarouselContainer.forEach(function(el) {
//         const NewsThumbsCarousel = new Swiper(el, {
//             speed: 1000,
//             slidesPerView: 6,
//             spaceBetween: 15,
//             watchOverflow: true
//         });
//     })
//     const NewsCarouselContainer = [...document.querySelectorAll('[data-carousel="noticia"]')];

//     NewsCarouselContainer.forEach(function(el) {
//         /*principal*/
//         console.log(el);
//         const NewsCarousel = new Swiper(el, {
//             speed: 1000,
//             slidesPerView: 1,
//             navigation: {
//                 nextEl: el.nextElementSibling.querySelector('.swiper-button-next--news'),
//                 prevEl: el.nextElementSibling.querySelector('.swiper-button-prev--news'),
//             },
//             spaceBetween: 0,
//             watchOverflow: true,

//         });
//     })
// }






/*==swiper revistas - comunicação ==*/
const RevistasCarouselContainer = document.querySelector('[data-carousel="revistas"]');

if (RevistasCarouselContainer) {

    const RevistasCarousel = new Swiper(RevistasCarouselContainer, {
        speed: 1000,
        slidesPerView: 1,
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: '.swiper-button-next--revistas',
            prevEl: '.swiper-button-prev--revistas',
        },
        // pagination: {
        //   el: ProdutoCarouselContainer.querySelector('.swiper-pagination'),
        //   clickable: true
        // },
        spaceBetween: 0,
        watchOverflow: true,
        breakpoints: {
            700: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            }
        }

    });
}

/*==swiper supermercados ==*/
const SupermercadosCarouselContainer = document.querySelector('[data-carousel="supermercados"]');

if (SupermercadosCarouselContainer) {
    /*thumbs*/
    const SupermercadosCarousel = new Swiper(SupermercadosCarouselContainer, {
        speed: 1000,
        slidesPerView: 'auto',
        // autoplay: {
        //   delay: 5000,
        // },
        // navigation: {
        //   nextEl: SupermercadosCarouselContainer.querySelector('.swiper-button-next'),
        //   prevEl: SupermercadosCarouselContainer.querySelector('.swiper-button-prev'),
        // },
        pagination: {
            el: SupermercadosCarouselContainer.querySelector('.swiper-pagination'),
            clickable: true
        },
        spaceBetween: 15,
        watchOverflow: true,
        breakpoints: {
            768: {
                slidesPerView: 3,
            }
        }

    });
}

/*==swiper supermercados ==*/
const PostosCarouselContainer = document.querySelector('[data-carousel="postos"]');

if (PostosCarouselContainer) {
    /*thumbs*/
    const PostosCarousel = new Swiper(PostosCarouselContainer, {
        speed: 1000,
        slidesPerView: 1,
        // autoplay: {
        //   delay: 5000,
        // },
        // navigation: {
        //   nextEl: PostosCarouselContainer.querySelector('.swiper-button-next'),
        //   prevEl: PostosCarouselContainer.querySelector('.swiper-button-prev'),
        // },
        // pagination: {
        //   el: PostosCarouselContainer.querySelector('.swiper-pagination'),
        //   clickable: true
        // },
        spaceBetween: 15,
        watchOverflow: true

    });
}

/*==swiper sobre home ==*/
const SobreCarouselContainer = document.querySelector('[data-carousel="home-sobre"]');

if (SobreCarouselContainer) {
    /*thumbs*/
    const SobreCarousel = new Swiper(SobreCarouselContainer, {
        speed: 1000,
        slidesPerView: 1,
        navigation: {
            nextEl: SobreCarouselContainer.querySelector('.swiper-button-next'),
            prevEl: SobreCarouselContainer.querySelector('.swiper-button-prev'),
        },
        pagination: {
            el: SobreCarouselContainer.querySelector('.swiper-pagination'),
            clickable: true
        },
        watchOverflow: true,
        breakpoints: {
            768: {
                slidesPerView: 'auto',
            }
        }

    });
}

/*==swiper news home ==*/
const HomeNewsCarouselContainer = document.querySelector('[data-carousel="home-news"]');

if (HomeNewsCarouselContainer) {
    /*thumbs*/
    const NewsHomeCarousel = new Swiper(HomeNewsCarouselContainer, {
        speed: 1000,
        slidesPerView: 'auto',
        // navigation: {
        //   nextEl: HomeNewsCarouselContainer.querySelector('.swiper-button-next'),
        //   prevEl: HomeNewsCarouselContainer.querySelector('.swiper-button-prev'),
        // },
        pagination: {
            el: HomeNewsCarouselContainer.querySelector('.swiper-pagination'),
            clickable: true
        },
        spaceBetween: 15,
        breakpoints: {
            768: {
                slidesPerView: 'auto',
                watchOverflow: true,
            }
        }

    });
}

/*==swiper produtos relacioandos ==*/
const ProdRelacionadosCarouselContainer = document.querySelector('[data-carousel="produto-relacionados"]');

if (ProdRelacionadosCarouselContainer) {
    /*thumbs*/
    const ProdRelacionadosCarousel = new Swiper(ProdRelacionadosCarouselContainer, {
        speed: 1000,
        slidesPerView: 'auto',
        watchOverflow: true,
        // navigation: {
        //   nextEl: ProdRelacionadosCarouselContainer.querySelector('.swiper-button-next'),
        //   prevEl: ProdRelacionadosCarouselContainer.querySelector('.swiper-button-prev'),
        // },
        pagination: {
            el: ProdRelacionadosCarouselContainer.querySelector('.swiper-pagination'),
            clickable: true
        },
        spaceBetween: 15,
        breakpoints: {
            768: {
                slidesPerView: 'auto',
                watchOverflow: true,
            }
        }

    });
}

/*==swiper produtos relacioandos ==*/
const DepoimentosCarouselContainer = document.querySelector('[data-carousel="depoimentos"]');

if (DepoimentosCarouselContainer) {
    /*thumbs*/
    const DepoimentosCarousel = new Swiper(DepoimentosCarouselContainer, {
        speed: 1000,
        slidesPerView: 1,
        navigation: {
            nextEl: DepoimentosCarouselContainer.querySelector('.swiper-button-next'),
            prevEl: DepoimentosCarouselContainer.querySelector('.swiper-button-prev'),
        },
        // pagination: {
        //     el: DepoimentosCarouselContainer.querySelector('.swiper-pagination'),
        //     clickable: true
        // },
        spaceBetween: 15,
        breakpoints: {
            768: {
                slidesPerView: 3,
            }
        }

    });
}



/*==swiper banner animação mobile ==*/


const carrosselHomeBanner = document.querySelector('[data-carousel="banner-home"]');

if (carrosselHomeBanner) {

    const carrosselHome = new Swiper(carrosselHomeBanner, {
        slidesPerView: 1,
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: carrosselHomeBanner.querySelector('.swiper-pagination'),
          clickable: true
        },
        spaceBetween: 0,
        watchOverflow: true,
        loop: true,
        on: {
            init() {
                var slidesVideo = document.querySelectorAll('.slide-video');
    for (var elSlideVideo of slidesVideo) {
        var video = elSlideVideo.querySelector('video');

        video.addEventListener('loadeddata', verDuracaoVideo);

        function verDuracaoVideo() {

        var duracaoVideo = video.duration * 1000;
        console.log(video);
        elSlideVideo.setAttribute('data-swiper-autoplay', duracaoVideo);
        carrosselHome.update()
    }
}
},
            slideChange() {
                const activeSlide = this.slides[this.activeIndex];

                
                var slidesVideo = carrosselHomeBanner.querySelectorAll('.slide-video');

                for (var slide of this.slides) {
                    
                    if (activeSlide.querySelector('video')) {
                        var videos = activeSlide.querySelectorAll('video');
                        for (var elVideo of videos) {
                            elVideo.play();
                        }
                    }

                    else {
                        

                        for (var elSlideVideo of slidesVideo) {
                            var videoDesk = elSlideVideo.querySelectorAll('video');
                            for (var elVideo of videoDesk) {
                                elVideo.pause();
                                elVideo.currentTime = 0;
                            }
                        }
                    }

                }

            }
        }
    })

}
//       init() {
//         console.log('start');
//         const activeSlide = this.slides[this.activeIndex];
//         if (activeSlide.querySelector('video')) {
//           let videos = [...activeSlide.querySelectorAll('video')];
//           // let video = videos.find(c => c.offsetParent !== null);
          
//           let video = activeSlide.querySelector('video');
//           console.log(video);
//           video.play();
//           video.addEventListener('canplaythrough', () => { video.play(); });
//           video.addEventListener('ended', () => {
//             console.log('acabou');
//             carrosselHome.slideNext();
//             // if (this.slides[this.activeIndex] === activeSlide) {
//             //   this.slideNext();
//             // }
//           });
//           video.dataset.hasEvents = true;
//         }
//         else {
//             console.log('com imagem!');
            
//             setTimeout(3000);
//             this.slideNext();
//         }
//       },
//       slideChangeTransitionStart() {
//         const activeSlide = this.slides[this.activeIndex];
//         // const previousSlide = this.activeIndex > 0 ? this.slides[this.activeIndex - 1] : null;
//         console.log(activeSlide);

//         if (activeSlide.querySelector('video')) {
//             console.log('com video');
//             let video = activeSlide.querySelector('video');
//             video.play();
//             video.addEventListener('canplaythrough', () => { video.play(); });
//             video.addEventListener('ended', () => {
//             console.log('acabouuu');
//             carrosselHome.slideNext();
//             // if (this.slides[this.activeIndex] === activeSlide) {
//             //   this.slideNext();
//             // }
//           });
//           video.dataset.hasEvents = true;

//         }

//         else {
//             console.log('com imagemmm!');
//             setTimeout(this.slideNext(), 5000);
//             // setTimeout(3000);
//             // this.slideNext();
//         }

//       }
//     }

//     });
// }

// alert(vid.duration); 


/*==swiper banner animação mobile ==*/
// const BannerAnimMobileCarouselContainerH = document.querySelector('[data-carousel="banner-mobile-h"]');

// if (BannerAnimMobileCarouselContainerH) {

//     const RevistasCarousel = new Swiper(BannerAnimMobileCarouselContainerH, {
//         slidesPerView: 1,
//         autoplay: {
//             delay: 5000,
//         },
//         // navigation: {
//         //     nextEl: '.swiper-button-next--revistas',
//         //     prevEl: '.swiper-button-prev--revistas',
//         // },
//         // pagination: {
//         //   el: ProdutoCarouselContainer.querySelector('.swiper-pagination'),
//         //   clickable: true
//         // },
//         spaceBetween: 0,
//         watchOverflow: true

//     });
// }


var menuFixo = document.getElementById('menuFixo');
var bannerFixo = document.getElementById('bannerFixo');

if (bannerFixo) {


    const loader = document.querySelector('.loader-container');

    window.addEventListener('load', function(e) {
        console.log('All loaded!');
        loader.classList.add('is-hidden');
    })


    // function isLoaded(img) {
    //     console.log(img.complete);
    // }
    // imgs.forEach(function(img) {
    //     isLoaded(img)
    // })


    // console.log(imgs);



    var alturaJanela = window.innerHeight;

    var alturaBanner = bannerFixo.offsetHeight;
    var larguraBanner = bannerFixo.offsetWidth;

    /*circulos*/
    // var diametroCirculo = (Math.sqrt((alturaBanner * alturaBanner) + (larguraBanner * larguraBanner)))/2;
    // document.documentElement.style.setProperty('--d-circulo', diametroCirculo + 'px');

    // console.log(alturaBanner);

    /*==apenas se for home==*/

    function animarScroll() {

        var rolagem = window.scrollY; /*posição do scroll*/
        var slide = document.querySelectorAll('.slide'); /*todos os slides*/
        var nSlide = parseInt((rolagem / alturaJanela), 10);
        var scale = (((rolagem / alturaJanela) - nSlide) - 1) * -2; /*valor scale (de 2 a 0 - para 1 cair no meio da animação)*/
        var firstSlide = document.querySelector('.slide1'); /*elemento do fim da animação */
        var nextSlide = nSlide + 1;

        // console.log('Slide length: ', slide.length);
        // console.log('Rolagem: ', rolagem);
        // console.log('Slide length x altura janela: ', slide.length * alturaJanela)

        if (rolagem < slide.length * alturaJanela) {
            menuFixo.classList.add('is--fixed');
            bannerFixo.classList.add('is--fixed');

            for (var [i, elSlide] of slide.entries()) { /*atribui um indice a cada um dos slides */
                elSlide.classList.remove('active'); /*tira a classe active de todos slides */
                elSlide.classList.remove('ready');
            };
            slide[nSlide].classList.add('active'); /*ativa o slide referente à posição do scroll*/
            if (nextSlide <= (slide.length - 1)) {
                // console.log('mais slides');
                slide[nextSlide].classList.add('ready');
            } else {
                slide[0].classList.add('ready');
            }
        } else {
            menuFixo.classList.remove('is--fixed');
            bannerFixo.classList.remove('is--fixed');
            firstSlide.classList.remove('ready');
            for (var elSlide of slide) {
                elSlide.classList.remove('active');
            }
        }

        /*==variáveis css==*/
        document.documentElement.style.setProperty('--scale', scale);
        document.documentElement.style.setProperty('--opacity', scale.toFixed(1));
        document.documentElement.style.setProperty('--scroll', rolagem + '%');
    }

    window.addEventListener('scroll', animarScroll);
    window.addEventListener('load', animarScroll);
}
/*==fim animação banner home==*/


// const skipBtn = document.querySelector('#down-button');

// skipBtn.addEventListener('click', function(e) {
//     bannerFixo.classList.remove('is--fixed');
// })



/*==FILTROS PRODUTOS==*/
var childrenFilters = document.querySelectorAll('ul.children');

if (childrenFilters) {

    for (var elChildFilter of childrenFilters) {
        var elParentFilter = elChildFilter.closest('li');
        var labelParentFilter = elParentFilter.querySelector('label');
        // console.log(elParentFilter);
        elParentFilter.classList.add('closed');
        var btOpenFilter = document.createElement('span');
        labelParentFilter.appendChild(btOpenFilter);
        btOpenFilter.addEventListener('click', toggleOpen);
    }

    function toggleOpen() {
        event.preventDefault();
        event.currentTarget.closest('li').classList.toggle('closed');
    }
}

/*==marcar checkbox filtros produtos==*/

var radioProdutos = document.querySelectorAll('input[name="ofcategorias_produtos[]"]');

for (var elRadioProduto of radioProdutos) {
    elRadioProduto.classList.remove('checked');
    elRadioProduto.addEventListener('click', toggleCheck);
}

function toggleCheck() {
    var checkedRadioProdutos = document.querySelectorAll('.checked');
    // event.preventDefault();
    elRadioProduto.classList.remove('checked');
    for (var elCheckedRadioProdutos of checkedRadioProdutos) {
        elCheckedRadioProdutos.classList.remove('checked');
    }
    event.currentTarget.classList.add('checked');
}

/*==marcar checkbox filtros unidades==*/

var radioUnidades = document.querySelectorAll('input[name="oftipo_unidade[]"]');

for (var elRadioUnidade of radioUnidades) {
    elRadioUnidade.classList.remove('checked');
    elRadioUnidade.addEventListener('click', toggleCheckUnidades);
}

function toggleCheckUnidades() {
    var checkedRadioUnidades = document.querySelectorAll('.checked');
    // event.preventDefault();
    elRadioUnidade.classList.remove('checked');
    for (var elCheckedRadioUnidades of checkedRadioUnidades) {
        elCheckedRadioUnidades.classList.remove('checked');
    }
    event.currentTarget.classList.add('checked');
}

/*==marcar checkbox filtros unidades==*/

var radioEstados = document.querySelectorAll('input[name="ofunidades_cidade[]"]');

for (var elRadioEstado of radioEstados) {
    elRadioEstado.classList.remove('checked');
    elRadioEstado.addEventListener('click', toggleCheckEstados);
}

function toggleCheckEstados() {
    var checkedRadioEstados = document.querySelectorAll('.checked');
    // event.preventDefault();
    elRadioEstado.classList.remove('checked');
    for (var elCheckedRadioUnidades of checkedRadioEstados) {
        elCheckedRadioUnidades.classList.remove('checked');
    }
    event.currentTarget.classList.add('checked');
}

/*==marcar checkbox filtros unidades==*/

var radioEstadosContato = document.querySelectorAll('input[name="ofestados_representantes[]"]');

for (var elRadioEstadoContato of radioEstadosContato) {
    elRadioEstadoContato.classList.remove('checked');
    elRadioEstadoContato.addEventListener('click', toggleCheckEstadosContato);
}

function toggleCheckEstadosContato() {
    var checkedRadioEstadosContato = document.querySelectorAll('.checked');
    // event.preventDefault();
    elRadioEstadoContato.classList.remove('checked');
    for (var elCheckedRadioEstados of checkedRadioEstadosContato) {
        elCheckedRadioEstados.classList.remove('checked');
    }
    event.currentTarget.classList.add('checked');
}

/*==modal videos==*/
var btsModal = document.querySelectorAll('.news-card__link');
var btsFechar = document.querySelectorAll('.videos-modal__btn');
var allModal = document.querySelectorAll('.videos-modal__bg-modal');

for (var elBtModal of btsModal) {
    elBtModal.addEventListener('click', abrirModal);
}

for (var elBtFechar of btsFechar) {
    // console.log(btsFechar);
    elBtFechar.addEventListener('click', fecharModal);
}



function abrirModal() {
    var attrBtModal = event.target.getAttribute('data-btmodal');
    console.log(attrBtModal);
    document.querySelector('[data-modal="' + attrBtModal + '"]').classList.add('active');
}

function fecharModal() {
    console.log('fechar');
    for (var elModal of allModal) {
        elModal.classList.remove('active')
    }

    for (var elModal of allModal) {

        var iframe = elModal.querySelector('iframe');
        var video = elModal.querySelector('video');
        if (iframe) {
            var iframeSrc = iframe.src;
            iframe.src = iframeSrc;
        }
        if (video) {
            video.pause();
        }

    }
}


// Accessibility

sessionStorage.getItem('contrast') && +sessionStorage.getItem('contrast') && document.body.classList.add('has--high-contrast');

if (document.querySelector('[data-contrast]')) {
    document.querySelector('[data-contrast]').addEventListener('click', () => {
        document.body.classList.toggle('has--high-contrast');

        sessionStorage.setItem('contrast', document.body.classList.contains('has--high-contrast') ? 1 : 0);
    }, true);
}

if (sessionStorage.getItem('fontsize')) document.body.style.fontSize = sessionStorage.getItem('fontsize') + 'px';

if (document.querySelector('[data-fontsize-decrease]')) {
    document.querySelector('[data-fontsize-decrease]').addEventListener('click', (event) => {
        event.preventDefault();
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size'));

        document.body.style.fontSize = --fontsize + 'px';

        sessionStorage.setItem('fontsize', fontsize);
    }, true);
}

if (document.querySelector('[data-fontsize-increase]')) {
    document.querySelector('[data-fontsize-increase]').addEventListener('click', (event) => {
        event.preventDefault();
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size'));

        document.body.style.fontSize = ++fontsize + 'px';

        sessionStorage.setItem('fontsize', fontsize);
    }, true);
}

var btWhats = document.getElementById('bt-whats');
var elWhats = document.getElementById('whatsapp');

if (btWhats) {
    btWhats.addEventListener('click', toggleWhats);


    function toggleWhats() {
        elWhats.classList.toggle('active');
        console.log('test')
    }
}

const revLink = [...document.querySelectorAll('.revista-card-link')];

revLink.forEach(function(el) {
    el.addEventListener('click', function(e) {
        console.log(e.target);
    })
})

const catLink = [...document.querySelectorAll('.catalogos-container__btn')];

catLink.forEach(function(el) {
    el.addEventListener('click', function(e) {
        console.log(e.target);
    })
})


/*==relatorios balanco==*/

var selectBalanco = document.getElementById('selectBalanco');
var allBtsBalanco = document.querySelectorAll('.balanco-container__btn');

if (selectBalanco) {

selectBalanco.addEventListener('change', showBtn);

function showBtn() {
    for (var elBtsBalanco of allBtsBalanco) {
        elBtsBalanco.classList.remove('active');
    }
    var valSelect = selectBalanco.value;
    console.log(valSelect);
    var btBalanco = document.querySelector('[data-btn="' + valSelect + '"]');
    console.log(btBalanco);
    btBalanco.classList.add('active');
}
}

/*==select SAC==*/

var selectManifestacao = document.getElementById('contatoManifestacao');
var fieldsReclamacao = document.getElementById('sac-grid__reclamacao');

if (selectManifestacao) {

selectManifestacao.addEventListener('change', showReclamacao);

function showReclamacao() {
    var valSelectManifestacao = selectManifestacao.value;
    console.log(valSelectManifestacao);

    if (valSelectManifestacao == 'Reclamação') {
        fieldsReclamacao.classList.add('active');
    }

    else {
        fieldsReclamacao.classList.remove('active');
    }
}
}

/*==COOKIES==*/

(function () {
    'use strict';

    var cookieAlert = document.getElementById('cookiealert');
    var acceptCookies = document.getElementById('acceptcookies');

    if (!cookieAlert) {
       return;
    }

    cookieAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)

    // Show the alert if we cant find the "acceptCookies" cookie
    if (!getCookie('acceptCookies')) {
        cookieAlert.classList.add('show'); 
    }

    // When clicking on the agree button, create a 1 year
    // cookie to remember user's choice and close the banner
    acceptCookies.addEventListener('click', function () {
        setCookie('acceptCookies', true, 365);
        cookieAlert.classList.remove('show');
    });

    // Cookie functions from w3schools
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    function getCookie(cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
})();

var btsFechar = document.querySelector('.bt-fechar');
var elModal = document.querySelector('.home-modal');


if (elModal) {
btsFechar.addEventListener('click', fecharModal); 
document.body.classList.add('has--modal');

function fecharModal() {
  elModal.classList.remove('active');
  document.body.classList.remove('has--modal');
}
}